<script setup lang="ts">
    import type { IWidgetProps } from '~/composables/widgets';
    import type { HighlightsContainerData } from '~/@types/cms';
    /**
     * Highlights Widget Container
     * contains a list of HighlightsWidgets or ProductWidgets (the same except for the award property)
     */
    const props = defineProps<IWidgetProps<HighlightsContainerData>>();
    const { data, getValueSequence } = useWidget(props);

    // validate data here and perform additional async calls if required
    const sequence = computed(() => {
        return getValueSequence(data.value);
    });
</script>
<template>
    <lazy-org-highlights-container
        :intro="data.intro"
        :watermark="data.watermark"
        :background="data.style?.background_color"
        class="cms-highlights-widget-container"
        :list="sequence" />
</template>
